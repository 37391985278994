import _afterRequest from "./afterRequest.json";
import _beforeRequest from "./beforeRequest.json";
import _browser from "./browser.json";
import _cache from "./cache.json";
import _content from "./content.json";
import _cookie from "./cookie.json";
import _creator from "./creator.json";
import _entry from "./entry.json";
import _har from "./har.json";
import _header from "./header.json";
import _log from "./log.json";
import _page from "./page.json";
import _pageTimings from "./pageTimings.json";
import _postData from "./postData.json";
import _query from "./query.json";
import _request from "./request.json";
import _response from "./response.json";
import _timings from "./timings.json";
var exports = {};
exports = {
  afterRequest: _afterRequest,
  beforeRequest: _beforeRequest,
  browser: _browser,
  cache: _cache,
  content: _content,
  cookie: _cookie,
  creator: _creator,
  entry: _entry,
  har: _har,
  header: _header,
  log: _log,
  page: _page,
  pageTimings: _pageTimings,
  postData: _postData,
  query: _query,
  request: _request,
  response: _response,
  timings: _timings
};
export default exports;
export const afterRequest = exports.afterRequest;