export default {
  "$id": "log.json#",
  "$schema": "http://json-schema.org/draft-06/schema#",
  "type": "object",
  "required": [
    "version",
    "creator",
    "entries"
  ],
  "properties": {
    "version": {
      "type": "string"
    },
    "creator": {
      "$ref": "creator.json#"
    },
    "browser": {
      "$ref": "browser.json#"
    },
    "pages": {
      "type": "array",
      "items": {
        "$ref": "page.json#"
      }
    },
    "entries": {
      "type": "array",
      "items": {
        "$ref": "entry.json#"
      }
    },
    "comment": {
      "type": "string"
    }
  }
}
;